import DialogActions from '../action/dialog-actions';
import DocumentTitle from '../utility/document-title';
import { downloadFile } from '../utility/download';
import { ENDPOINTS } from '../config/api';
import FilterActions from '../action/filter-actions';
import Filters from '../component/filters';
import FilterStore from '../store/filter-store';
import { formatFilters } from '../utility/fields';
import List from '../component/list';
import NotificationActions from '../action/notification-actions';
import { prepareListEndpoint } from '../utility/list';
import PropTypes from 'prop-types';
import { request } from '../utility/request';
import { RESULTS } from '../config/tables';
import UserStore from '../store/user-store';
import { withRouter } from 'react-router-dom';
import { CUSTOM_DIALOGS, FAUX_LOADING_TIME, KEYWORD } from '../config/app';
import { number, queryPrefix } from '../utility/format';
import React, { Component } from 'react';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundImageClass: this.getBackgroundImageClass(),
            data: [],
            eav: 0,
            endpointParams: '',
            currentPage: null,
            loading: true,
            permissions: false,
            pagination: {},
            tableHeader: [],
            total: 0,
            userOrganisationId: null
        };

        this.onChange = this.onChange.bind(this);
    }


    // Mounting
    UNSAFE_componentWillReceiveProps(nextProps) {
        let { number } = nextProps.match.params;

        this.populateData(number);
    }

    UNSAFE_componentWillMount() {
        let { number } = this.props.match.params;

        this.populateData(number);

        UserStore.listen(this.onChange);
    }

    componentWillUnmount() {
        UserStore.unlisten(this.onChange);
    }


    // Handler
    handleBulkEdit() {
        // Export results after confirm
        DialogActions.open(CUSTOM_DIALOGS.bulkEdit, {
            // Get the latest endpoint params
            endpointParams: this.getEndpointParams(),
            reloadData: this.reloadData.bind(this),
            total: this.state.total
        });
    }

    handleExport() {
        let endpointParams = this.getEndpointParams(),
            { total, permissions } = this.state;

        // If there are no results prevent
        if (total === 0) { // eslint-disable-line no-magic-numbers
            NotificationActions.push({
                delay: 0,
                message: 'No results to found export.',
                type: 'warning'
            });

            return;
        }

        // Export results after confirm
        DialogActions.open(CUSTOM_DIALOGS.exportResults, endpointParams, permissions);
    }

    handleSubmit(filters) {
        let endpointParams = this.prepareEndpointParams(filters);

        this.setState({
            endpointParams: endpointParams,
        }, () => {
            // Reset Page to first
            this.props.history.push('/dashboard/page/1');
        });
    }

    handleStatusHeaderClick(value) {
        const fields = FilterStore.getState().filters;

        fields.resultStatus = value;

        FilterActions.save(fields);
        this.handleSubmit(fields);
    }

    prepareEndpointParams(filters) {
        let {
            dateFrom, dateTo, market, rto, search,
            resultType, searchBy, organisation, premium,
            impact, sortOrder, resultStatus
        } = filters;

        let endpointParams = '';

        // Add date
        if (dateTo && dateFrom) {
            endpointParams += `${queryPrefix(endpointParams)}date_from=${dateFrom}&date_to=${dateTo}`;
        }

        // Add search by keyword/organisation
        if (searchBy === KEYWORD) {
            if (search) {
                endpointParams += `${queryPrefix(endpointParams)}search=${search}`;
            }
        } else if (organisation) {
            endpointParams += `${queryPrefix(endpointParams)}reference=${organisation.value}&search_by=organisation`;
        }

        // Add type
        if (resultType) {
            endpointParams += `${queryPrefix(endpointParams)}result_type=${resultType}`;
        }

        // Add market
        if (market) {
            endpointParams += `${queryPrefix(endpointParams)}market=${market}`;
        }

        // Add region
        if (rto) {
            endpointParams += `${queryPrefix(endpointParams)}rto=${rto}`;
        }

        // Add premium
        if (premium) {
            endpointParams += `${queryPrefix(endpointParams)}premium=${premium}`;
        }

        // Add impact
        if (impact) {
            endpointParams += `${queryPrefix(endpointParams)}impact=${impact}`;
        }

        // Add sort order
        if (sortOrder) {
            endpointParams += `${queryPrefix(endpointParams)}sort_order=${sortOrder}`;
        }

        // Add result status
        if (resultStatus) {
            endpointParams += `${queryPrefix(endpointParams)}status=${resultStatus}`;
        }

        return endpointParams;
    }

    reloadData() {
        this.populateData(1); // eslint-disable-line
    }

    responseHasErrors(response) {
        if (response.hasOwnProperty('message')) {
            NotificationActions.push({
                message: response.message,
                type: 'error'
            });

            return true;
        }

        return false;
    }

    onChange() {
        const currentOrganisationId = UserStore.getState().user.organisationId;

        if (this.state.userOrganisationId !== currentOrganisationId) {
            this.reloadData();
            this.setState({
                userOrganisationId: currentOrganisationId
            });
        }
    }

    populateData(number) {
        // Get the latest endpoint params
        let endpointParams = this.getEndpointParams(),
            endpointPrepared = prepareListEndpoint(endpointParams, this.state.limit, number);

        this.setState({ currentPage: parseInt(number), loading: true });

        // Make request
        request(ENDPOINTS.RESULTS + endpointPrepared)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.data) {
                    let { data, meta } = response,
                        newPermissions = this.state.permissions,
                        tableHeader = RESULTS.tableHeaderData;

                    // Remove status column title if can't view status
                    if (!meta.permissions.can_view_status) {
                        tableHeader[tableHeader.length - 1].data.value = ''; // eslint-disable-line no-magic-numbers
                    }

                    // Only set permissions the first time
                    if (!newPermissions) {
                        newPermissions = meta.permissions;
                    }

                    this.setState({
                        data: data,
                        eav: meta.eav,
                        pagination: meta.pagination,
                        permissions: newPermissions,
                        tableHeader: tableHeader,
                        total: meta.pagination.total || meta.stories_generated,
                    }, () => {
                        setTimeout(() => {
                            this.setState({ loading: false });
                        }, FAUX_LOADING_TIME);
                    });
                }
            });
    }

    getEndpointParams() {
        let filters = formatFilters(FilterStore.getState().filters),
            preparedEndpointParams = this.prepareEndpointParams(filters);

        // Check if the state endpoint params matches the filters in local storage
        if (this.state.endpointParams !== preparedEndpointParams) {
            // Update the state endpoint params with the updated local storage filters
            this.setState({
                endpointParams: preparedEndpointParams,
            });
        }

        return preparedEndpointParams;
    }


    // Getters
    getActionsConfig() {
        return {
            class: 'dropdown-actions || tertiary align-right width-auto',
            icon: 'stack',
            buttons: [
                {
                    label: 'Export',
                    action: this.handleExport.bind(this)
                },
                {
                    label: 'Bulk edit',
                    action: this.handleBulkEdit.bind(this)
                }
            ]
        };
    }

    getBackgroundImageClass() {
        let generated,
            max = 4,
            min = 1;

        generated = Math.floor(Math.random() * (max - min) + min);

        return `image-${generated}`;
    }

    getName() {
        if (UserStore.getState().user === null) return; // Check user loaded

        let { firstName, lastName } = UserStore.getState().user;

        return `${firstName} ${lastName}`;
    }

    getOrganisation() {
        let { user } = UserStore.getState();
        if (user === null) {
            // User should be always loaded.
            return;
        }

        let { organisation } = user;
        if (organisation !== null) {
            let { sub_organisation } = organisation;
            if (sub_organisation !== null) {
                let { name } = sub_organisation;
                // Return organisation name when organisation (and sub organisation) are existing.
                return name;
            }
        }
        // Return default when no organisation has been assigned to the user.
        return 'Tourism NZ';
    }


    // Render
    renderExport() {
        let { permissions } = this.state;

        if (!permissions) {
            return;
        }

        return (
            <button className="button septenary || export" onClick={this.handleExport.bind(this)}>
                <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                    <use xlinkHref="/assets/icons/_sprite.svg#download"/>
                </svg>
                <span className="button-label">Export</span>
            </button>
        );
    }

    renderBulkEdit() {
        let { permissions } = this.state;

        if (!permissions) {
            return;
        }

        if (permissions.can_bulk_moderate) {
            return (
                <button className="button septenary || export" onClick={this.handleBulkEdit.bind(this)}>
                    <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                        <use xlinkHref="/assets/icons/_sprite.svg#stack"/>
                    </svg>
                    <span className="button-label">Bulk Edit</span>
                </button>
            );
        }
    }

    renderSubmit() {
        return (
            <button className="button primary || submit" type="submit" onClick={this.handleSubmit.bind(this)}>
                <svg viewBox="0 0 40 40" width="40" height="40" className="icon">
                    <use xlinkHref="/assets/icons/_sprite.svg#search"/>
                </svg>
                <span className="button-label">Search</span>
            </button>
        );
    }

    renderTextEav() {
        let { eav, total } = this.state;

        // Only display if there are results
        if (parseInt(total) > 0) { // eslint-disable-line no-magic-numbers
            return (
                <div className="item || total">
                    <h5 className="sub-heading">with an Equivalent Advertising Value (EAV) of</h5>
                    <p className="style-h1 || pure-pakati"><sup>$</sup>{number(eav)}</p>
                </div>
            );
        }
    }

    renderTextTotal() {
        let { total } = this.state;

        // Only display if there are results
        if (parseInt(total) > 0) { // eslint-disable-line no-magic-numbers
            return (
                <div className="item || total">
                    <h5 className="sub-heading">The number of stories generated is</h5>
                    <p className="style-h1 || pure-pakati">{total}</p>
                </div>
            );
        }
    }

    render() {
        let bannerClass = `banner ${this.state.backgroundImageClass}`;

        if (this.state.loading) {
            bannerClass += ' is-loading-Befade'; // Not is-loading
        }

        return (
            <DocumentTitle title="Dashboard">
                <main role="main" className="main || dashboard">
                    <div className="main-inner">
                        <div className={bannerClass}>
                            <div className="inner || constrain-width large">

                                {/* Title Sequence */}
                                <div role="group" className="title-sequence">
                                    <div className="item || title">
                                        <h5 className="sub-heading">Hi {this.getName()}, here's your media coverage for</h5>
                                        <h1 className="pure-pakati">{this.getOrganisation()}</h1>
                                    </div>
                                    {this.renderTextTotal()}
                                    {this.renderTextEav()}
                                </div>

                                {/* Filter Bar */}
                                <div className="banner-actions">
                                    <Filters onSubmit={this.handleSubmit.bind(this)}/>
                                    <div className="filter-buttons-left || hide-to-desktop">
                                        {this.renderExport()}
                                        {this.renderBulkEdit()}
                                    </div>
                                    <div className="filter-buttons-right || hide-to-desktop">
                                        {this.renderSubmit()}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <List
                            onSubmit={this.handleSubmit.bind(this)}
                            data={this.state.data}
                            header={this.state.tableHeader}
                            onStatusHeaderClick={this.handleStatusHeaderClick.bind(this)}
                            number={this.state.currentPage}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            view={RESULTS.view} />
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

Dashboard.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};

export default withRouter(Dashboard);
