export const ENDPOINTS = {
    'AIRLINE'                                       : '/api/organisations/airlines',
    'CHECK_AUTH'                                    : '/api/users/check-auth',
    'EVENT_ORGANISER'                               : '/api/organisations/event-organisers',
    'LIST_AIRLINES'                                 : '/api/organisations/airlines',
    'LIST_AUDIENCE'                                 : '/api/options/audience',
    'LIST_COUNTRIES'                                : '/api/options/countries',
    'LIST_EVENT_ORGANISERS'                         : '/api/organisations/event-organisers',
    'LIST_INTERESTS'                                : '/api/options/interests',
    'LIST_INTERESTS_FOR_PARTNERSHIPS_AND_PROJECTS'  : '/api/options/interests?result_type=partnerships_and_projects',
    'LIST_MARKETS'                                  : '/api/options/markets',
    'LIST_MEDIA_TYPES'                              : '/api/options/media-types',
    'LIST_MOTIVATORS'                               : '/api/options/motivators',
    'LIST_USERS_OPTIONS'                            : '/api/options/users',
    'LIST_ORGANISATIONS'                            : '/api/organisations',
    'LIST_OPERATORS'                                : '/api/organisations/operators',
    'LIST_REPORTING_PERIOD'                         : '/api/results/reporting-periods',
    'LIST_REGIONS'                                  : '/api/options/regions',
    'LIST_RESULT_TYPES'                             : '/api/options/result-types',
    'LIST_RTOS'                                     : '/api/organisations/regional-tourism-operators',
    'LIST_USERS'                                    : '/api/users',
    'LOGIN'                                         : '/api/oauth/token',
    'LOGIN_REFRESH'                                 : '/api/oauth/token/refresh',
    'OFFSHORE_AGENCY'                               : '/api/organisations/offshore-agencies',
    'OPERATOR'                                      : '/api/organisations/operators',
    'PASSWORD_FORGOT'                               : '/api/users/password/reset',
    'PASSWORD_RESET'                                : '/api/users/password/change',
    'REGISTER'                                      : '/api/users/register',
    'RESULTS'                                       : '/api/results',
    'RESULTS_BULK_CHANGE'                           : '/api/results/moderate/',
    'RESULTS_EXPORT'                                : '/api/results/export',
    'RESULTS_PUBLISH_RETRIEVE_MONTHS'               : '/api/results/reporting-periods',
    'RESULTS_PUBLISH_ALL'                           : '/api/results/reporting-periods/close',
    'RESULTS_GET_FILE_KEY'                             : '/api/results/get-file-key',
    'RESULTS_MEDIA'                                 : '/api/results/media',
    'RESULTS_SYNDICATED'                            : '/api/results/syndicate',
    'RTO'                                           : '/api/organisations/regional-tourism-operators',
    'USER'                                          : '/api/users',
    'USER_CURRENT'                                  : '/api/users/me',
    'USER_INVITE'                                   : '/api/users/invite'
};


export const HTTP_CODES = {
    'UNAUTHORIZED': 401,
    'OK': 200
};
